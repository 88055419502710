import { ApiError } from "@lib";

export const ru: any = {
    [ApiError.SUCCESS]: "Успех.",

    [ApiError.EMAIL_ALREADY_EXISTS]: "Почта уже занята.",
    [ApiError.INVALID_LOGIN_OR_PASSWORD]: "Неверный логин или пароль.",
    [ApiError.INVALID_EMAIL]: "Неверная почта.",
    [ApiError.INVALID_USER]: "Неверный пользователь.",
    [ApiError.INVALID_TOKEN]: "Неверный токен.",
    [ApiError.INVALID_OLD_PASSWORD]: "Неверный старый пароль.",
    [ApiError.PASSWORDS_NOT_MATCHED]: "Пароли не совпадают.",

    [ApiError.SOCIAL_NOT_FOUND]: "Соцсеть не подключена.",
    [ApiError.SOCIAL_UNSUPPORTED]: "Соцсеть не поддерживается.",
    [ApiError.SOCIAL_ALREADY_EXISTS]: "Аккаунт соцсети уже подключён.",

    [ApiError.USER_NOT_FOUND]: "Пользователь не найден.",
    [ApiError.USER_UNABLE_CREATE]: "Не удалось создать пользователя.",
    [ApiError.USER_UNABLE_UPDATE]: "Не удалось обновить пользователя.",

    [ApiError.EMPLOYEE_NOT_FOUND]: "Сотрудник не найден.",
    [ApiError.EMPLOYEE_UNABLE_CREATE]: "Не удалось создать сотрудника.",
    [ApiError.EMPLOYEE_UNABLE_UPDATE]: "Не удалось обновить сотрудника.",
    [ApiError.EMPLOYEE_UNABLE_DELETE]: "Не удалось удалить сотрудника.",

    [ApiError.COMPANY_NOT_FOUND]: "Компания не найдена.",
    [ApiError.COMPANY_UNABLE_CREATE]: "Не удалось создать компанию.",
    [ApiError.COMPANY_UNABLE_UPDATE]: "Не удалось обновить компанию.",
    [ApiError.COMPANY_PAYMENT_UNABLE_CREATE]: "Не удалось создать платёж.",
    [ApiError.COMPANY_PAYMENT_FUNDS]: "Недостаточно средств.",
    [ApiError.COMPANY_PRICING_UNABLE_UPDATE]: "Не удалось сменить тариф.",

    [ApiError.PERMISSIONS_NOT_FOUND]: "Права доступа не найдены.",
    [ApiError.PERMISSIONS_UNABLE_GRANT]: "Не удалось выдать права доступа.",

    [ApiError.CLIENT_NOT_FOUND]: "Клиент не найден.",
    [ApiError.CLIENT_UNABLE_CREATE]: "Не удалось создать клиента.",
    [ApiError.CLIENT_UNABLE_UPDATE]: "Не удалось обновить клиента.",
    [ApiError.CLIENT_UNABLE_DELETE]: "Не удалось удалить клиента.",
    [ApiError.CLIENT_UNABLE_IMPORT]: "Не удалось импортировать клиентов.",

    // office

    [ApiError.OFFICE_NOT_FOUND]: "Филиал не найден.",
    [ApiError.OFFICE_UNABLE_CREATE]: "Не удалось создать филиал.",
    [ApiError.OFFICE_UNABLE_UPDATE]: "Не удалось обновить филиал.",
    [ApiError.OFFICE_UNABLE_DELETE]: "Не удалось удалить филиал.",

    [ApiError.ORDER_NOT_FOUND]: "Заявка не найдена.",
    [ApiError.ORDER_UNABLE_CREATE]: "Не удалось создать заявку.",
    [ApiError.ORDER_UNABLE_UPDATE]: "Не удалось обновить заявку.",
    [ApiError.ORDER_UNABLE_DELETE]: "Не удалось удалить заявку.",

    [ApiError.ORDER_STAGE_NOT_FOUND]: "Этап не найден.",
    [ApiError.ORDER_STAGE_UNABLE_CREATE]: "Не удалось создать этап.",
    [ApiError.ORDER_STAGE_UNABLE_UPDATE]: "Не удалось обновить этап.",
    [ApiError.ORDER_STAGE_UNABLE_DELETE]: "Не удалось удалить этап.",

    [ApiError.ORDER_TYPE_NOT_FOUND]: "Тип заявки не найден.",
    [ApiError.ORDER_TYPE_UNABLE_CREATE]: "Не удалось создать тип заявки.",
    [ApiError.ORDER_TYPE_UNABLE_UPDATE]: "Не удалось обновить тип заявки.",
    [ApiError.ORDER_TYPE_UNABLE_DELETE]: "Не удалось удалить тип заявки.",

    [ApiError.PRODUCT_TYPE_NOT_FOUND]: "Тип продукта не найден.",
    [ApiError.PRODUCT_TYPE_UNABLE_CREATE]: "Не удалось создать тип продукта.",
    [ApiError.PRODUCT_TYPE_UNABLE_UPDATE]: "Не удалось обновить тип продукта.",
    [ApiError.PRODUCT_TYPE_UNABLE_DELETE]: "Не удалось удалить тип продукта.",

    [ApiError.WORK_NOT_FOUND]: "Работа не найдена.",
    [ApiError.WORK_UNABLE_CREATE]: "Не удалось создать работу.",
    [ApiError.WORK_UNABLE_UPDATE]: "Не удалось обновить работу.",
    [ApiError.WORK_UNABLE_DELETE]: "Не удалось удалить работу.",
    [ApiError.WORK_UNABLE_IMPORT]: "Не удалось импортировать работы.",
    [ApiError.WORK_UNABLE_EXPORT]: "Не удалось экспортировать работы.",

    // shop

    [ApiError.SHOP_NOT_FOUND]: "Магазин не найден.",
    [ApiError.SHOP_UNABLE_CREATE]: "Не удалось создать магазин.",
    [ApiError.SHOP_UNABLE_UPDATE]: "Не удалось обновить магазин.",
    [ApiError.SHOP_UNABLE_DELETE]: "Не удалось удалить магазин.",

    [ApiError.SALE_NOT_FOUND]: "Продажа не найдена.",
    [ApiError.SALE_UNABLE_CREATE]: "Не удалось создать продажу.",
    [ApiError.SALE_UNABLE_UPDATE]: "Не удалось обновить продажу.",
    [ApiError.SALE_UNABLE_DELETE]: "Не удалось удалить продажу.",

    [ApiError.SALE_STAGE_NOT_FOUND]: "Этап не найден.",
    [ApiError.SALE_STAGE_UNABLE_CREATE]: "Не удалось создать этап.",
    [ApiError.SALE_STAGE_UNABLE_UPDATE]: "Не удалось обновить этап.",
    [ApiError.SALE_STAGE_UNABLE_DELETE]: "Не удалось удалить этап.",

    [ApiError.SALE_TYPE_NOT_FOUND]: "Тип продажи не найден.",
    [ApiError.SALE_TYPE_UNABLE_CREATE]: "Не удалось создать тип продажи.",
    [ApiError.SALE_TYPE_UNABLE_UPDATE]: "Не удалось обновить тип продажи.",
    [ApiError.SALE_TYPE_UNABLE_DELETE]: "Не удалось удалить тип продажи.",

    // account

    [ApiError.ACCOUNT_NOT_FOUND]: "Счёт не найден.",
    [ApiError.ACCOUNT_UNABLE_CREATE]: "Не удалось создать счёт.",
    [ApiError.ACCOUNT_UNABLE_UPDATE]: "Не удалось обновить счёт.",
    [ApiError.ACCOUNT_UNABLE_DELETE]: "Не удалось удалить счёт.",

    [ApiError.TRANSACTION_NOT_FOUND]: "Транзакция не найдена.",
    [ApiError.TRANSACTION_UNABLE_CREATE]: "Не удалось создать транзакцию.",
    [ApiError.TRANSACTION_UNABLE_UPDATE]: "Не удалось обновить транзакцию.",
    [ApiError.TRANSACTION_UNABLE_TRANSFER]: "Не удалось переместить средства.",
    [ApiError.TRANSACTION_UNABLE_DELETE]: "Не удалось удалить транзакцию.",

    [ApiError.TRANSACTION_ITEM_NOT_FOUND]: "Статья транзакции не найдена.",
    [ApiError.TRANSACTION_ITEM_UNABLE_CREATE]: "Не удалось создать статью транзакции.",
    [ApiError.TRANSACTION_ITEM_UNABLE_UPDATE]: "Не удалось обновить статью транзакции.",
    [ApiError.TRANSACTION_ITEM_UNABLE_DELETE]: "Не удалось удалить статью транзакции.",

    // store

    [ApiError.STORE_NOT_FOUND]: "Склад не найден.",
    [ApiError.STORE_UNABLE_CREATE]: "Не удалось создать склад.",
    [ApiError.STORE_UNABLE_UPDATE]: "Не удалось обновить склад.",
    [ApiError.STORE_UNABLE_DELETE]: "Не удалось удалить склад.",

    [ApiError.GOOD_NOT_FOUND]: "Товар не найден.",
    [ApiError.GOOD_UNABLE_CREATE]: "Не удалось создать товар.",
    [ApiError.GOOD_UNABLE_UPDATE]: "Не удалось обновить товар.",
    [ApiError.GOOD_UNABLE_DELETE]: "Не удалось удалить товар.",
    [ApiError.GOOD_UNABLE_IMPORT]: "Не удалось импортировать товары.",

    [ApiError.GOOD_CATEGORY_NOT_FOUND]: "Категория не найдена.",
    [ApiError.GOOD_CATEGORY_UNABLE_CREATE]: "Не удалось создать категорию.",
    [ApiError.GOOD_CATEGORY_UNABLE_UPDATE]: "Не удалось обновить категорию.",
    [ApiError.GOOD_CATEGORY_UNABLE_DELETE]: "Не удалось удалить категорию.",

    [ApiError.GOOD_REGISTRATION_NOT_FOUND]: "Оприходование не найдено.",
    [ApiError.GOOD_REGISTRATION_UNABLE_CREATE]: "Не удалось создать оприходование.",
    [ApiError.GOOD_REGISTRATION_UNABLE_UPDATE]: "Не удалось обновить оприходование.",
    [ApiError.GOOD_REGISTRATION_UNABLE_DELETE]: "Не удалось удалить оприходование.",

    [ApiError.GOOD_WRITE_OFF_NOT_FOUND]: "Списание не найдено.",
    [ApiError.GOOD_WRITE_OFF_UNABLE_CREATE]: "Не удалось создать списание.",
    [ApiError.GOOD_WRITE_OFF_UNABLE_CREATE_NO_ITEMS]: "Нет товаров для списания.",
    [ApiError.GOOD_WRITE_OFF_UNABLE_UPDATE]: "Не удалось обновить списание.",
    [ApiError.GOOD_WRITE_OFF_UNABLE_DELETE]: "Не удалось удалить списание.",

    [ApiError.GOOD_INVENTORY_NOT_FOUND]: "Инвентаризация не найдена.",
    [ApiError.GOOD_INVENTORY_UNABLE_CREATE]: "Не удалось создать инвентаризацию.",
    [ApiError.GOOD_INVENTORY_UNABLE_UPDATE]: "Не удалось обновить инвентаризацию.",
    [ApiError.GOOD_INVENTORY_UNABLE_DELETE]: "Не удалось удалить инвентаризацию.",

    [ApiError.GOOD_INVENTORY_ITEM_NOT_FOUND]: "Товар в инвентаризации не найден.",
    [ApiError.GOOD_INVENTORY_ITEM_UNABLE_CREATE]: "Не удалось добавить товар в инвентаризацию.",
    [ApiError.GOOD_INVENTORY_ITEM_UNABLE_UPDATE]: "Не удалось обновить товар в инвентаризации.",
    [ApiError.GOOD_INVENTORY_ITEM_UNABLE_DELETE]: "Не удалось удалить товар из инвентаризации.",

    [ApiError.SUPPLIER_NOT_FOUND]: "Поставщик не найден.",
    [ApiError.SUPPLIER_UNABLE_CREATE]: "Не удалось создать поставщика.",
    [ApiError.SUPPLIER_UNABLE_UPDATE]: "Не удалось обновить поставщика.",
    [ApiError.SUPPLIER_UNABLE_DELETE]: "Не удалось удалить поставщика.",

    // template

    [ApiError.TEMPLATE_DOCUMENT_NOT_FOUND]: "Шаблон документа не найден.",
    [ApiError.TEMPLATE_DOCUMENT_UNABLE_CREATE]: "Не удалось создать шаблон документа.",
    [ApiError.TEMPLATE_DOCUMENT_UNABLE_UPDATE]: "Не удалось обновить шаблон документа.",
    [ApiError.TEMPLATE_DOCUMENT_UNABLE_DELETE]: "Не удалось удалить шаблон документа.",

    [ApiError.TEMPLATE_NOTIFICATION_NOT_FOUND]: "Шаблон смс не найден.",
    [ApiError.TEMPLATE_NOTIFICATION_UNABLE_CREATE]: "Не удалось создать шаблон смс.",
    [ApiError.TEMPLATE_NOTIFICATION_UNABLE_UPDATE]: "Не удалось обновить шаблон смс.",
    [ApiError.TEMPLATE_NOTIFICATION_UNABLE_DELETE]: "Не удалось удалить шаблон смс.",

    [ApiError.TEMPLATE_LABEL_NOT_FOUND]: "Шаблон ценника не найден.",
    [ApiError.TEMPLATE_LABEL_UNABLE_CREATE]: "Не удалось создать шаблон ценника.",
    [ApiError.TEMPLATE_LABEL_UNABLE_UPDATE]: "Не удалось обновить шаблон ценника.",
    [ApiError.TEMPLATE_LABEL_UNABLE_DELETE]: "Не удалось удалить шаблон ценника.",

    // plugins

    [ApiError.PLUGIN_NOT_FOUND]: "Расширение не найдено.",
    [ApiError.PLUGIN_UNABLE_CREATE]: "Не удалось создать расширение.",
    [ApiError.PLUGIN_UNABLE_UPDATE]: "Не удалось изменить расширение.",
    [ApiError.PLUGIN_UNABLE_DELETE]: "Не удалось удалить расширение.",
    [ApiError.PLUGIN_UNABLE_BIND]: "Не удалось включить расширение.",

    // salary

    [ApiError.SALARY_NOT_FOUND]: "Выплата не найдена.",
    [ApiError.SALARY_UNABLE_CREATE]: "Не удалось создать выплату.",
    [ApiError.SALARY_UNABLE_UPDATE]: "Не удалось изменить выплату.",
    [ApiError.SALARY_UNABLE_DELETE]: "Не удалось удалить выплату.",

    // reports

    [ApiError.REPORT_NOT_FOUND]: "Отчёт не найден.",

    //

    [ApiError.CLIENT_SOURCE_NOT_FOUND]: "Источник клиента не найден.",
    [ApiError.CLIENT_SOURCE_UNABLE_CREATE]: "Не удалось создать источник клиента.",

    [ApiError.PARTNER_NOT_FOUND]: "Идентификатор партнёра не найден.",

    [ApiError.SETTINGS_TABLE_NOT_FOUND]: "Настройки таблицы не найдены.",
    [ApiError.SETTINGS_TABLE_UNABLE_CREATE]: "Не удалось сохранить настройки таблицы.",
    [ApiError.SETTINGS_FORM_NOT_FOUND]: "Настройки формы не найдены.",
    [ApiError.SETTINGS_FORM_UNABLE_CREATE]: "Не удалось сохранить настройки формы.",
    [ApiError.SETTINGS_USER_NOT_FOUND]: "Настройки пользователя не найдены.",
    [ApiError.SETTINGS_USER_UNABLE_CREATE]: "Не удалось сохранить настройки пользователя.",

    [ApiError.PRICING_INVALID_OPERATION]: "Операция недоступна по текущему тарифному плану.",

    [ApiError.TRACKING_CODE_NOT_FOUND]: "Код отслеживания не найден.",

    [ApiError.HTTP_BADREQUEST]: "Некорректный запрос.",
    [ApiError.HTTP_UNAUTHORIZED]: "Пользователь не авторизован.",
    [ApiError.HTTP_FORBIDDEN]: "Нет доступа.",
    [ApiError.HTTP_NOT_FOUND]: "Не найдено.",
    [ApiError.HTTP_INTERNAL]: "Внутренняя ошибка.",

    [ApiError.UNKNOWN_ERROR]: "Неизвестная ошибка.",
};
