import {
    IGoodInventoryItemUseCase,
    IGoodInventoryItem,
    IGoodInventoryItemCreateDto,
    IGoodInventoryItemUpdateDto,
    IGoodInventoryItemReportResult,
    IReadQuery,
    ISelectedData,
} from "@lib";
import { Api } from "../axios";
import { DataLoadException } from "@/core/exceptions";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class GoodInventoryItemUseCase implements IGoodInventoryItemUseCase {
    public async select(
        companyId: string,
        inventoryId: string,
        query?: IReadQuery,
    ): Promise<ISelectedData<IGoodInventoryItem>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGoodInventoryItem>>(
                `/companies/${companyId}/good-inventories/${inventoryId}/items`,
                { params },
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectForWriteOff(
        companyId: string,
        inventoryId: string,
        limit: number,
    ): Promise<IGoodInventoryItem[]> {
        try {
            const params = { limit };
            const response = await Api.get<IGoodInventoryItem[]>(
                `/companies/${companyId}/good-inventories/${inventoryId}/items/for/write-off`,
                { params },
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(
        companyId: string,
        inventoryId: string,
        dto: IGoodInventoryItemCreateDto,
    ): Promise<IGoodInventoryItem> {
        try {
            const response = await Api.post<IGoodInventoryItem>(
                `/companies/${companyId}/good-inventories/${inventoryId}/items`,
                dto,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(
        companyId: string,
        inventoryId: string,
        itemId: string,
        dto: IGoodInventoryItemUpdateDto,
    ): Promise<IGoodInventoryItem> {
        try {
            const response = await Api.put<IGoodInventoryItem>(
                `/companies/${companyId}/good-inventories/${inventoryId}/items/${itemId}`,
                dto,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public increaseQuantity(
        companyId: string,
        inventoryId: string,
        itemId: string,
        quantity: number,
    ): Promise<IGoodInventoryItem> {
        throw new Error("Not implemented");
    }

    public decreaseQuantity(
        companyId: string,
        inventoryId: string,
        itemId: string,
        quantity: number,
    ): Promise<IGoodInventoryItem> {
        throw new Error("Not implemented");
    }

    public async delete(companyId: string, inventoryId: string, itemId: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/good-inventories/${inventoryId}/items/${itemId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteAll(companyId: string, inventoryId: string): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/good-inventories/${inventoryId}/items/actions/delete-all`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async report(companyId: string, inventoryId: string): Promise<IGoodInventoryItemReportResult> {
        try {
            const response = await Api.get<IGoodInventoryItemReportResult>(
                `/companies/${companyId}/good-inventories/${inventoryId}/items/actions/report`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async populateFromStore(companyId: string, inventoryId: string): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/good-inventories/${inventoryId}/items/actions/populate`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
