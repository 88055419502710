import {
    IGoodWriteOff,
    IGoodWriteOffUseCase,
    IGoodWriteOffCreateDto,
    IGoodWriteOffUpdateDto,
    IReadQuery,
    ISelectedData,
} from "@lib";
import { Api } from "../axios";
import { DataLoadException } from "@/core/exceptions";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class GoodWriteOffUseCase implements IGoodWriteOffUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<IGoodWriteOff>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGoodWriteOff>>(`/companies/${companyId}/good-write-offs`, {
                params,
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async getMany(companyId: string, writeoffIds: string[]): Promise<IGoodWriteOff[]> {
        try {
            const params = { id: writeoffIds };
            const response = await Api.get<IGoodWriteOff[]>(`/companies/${companyId}/good-write-offs/many`, {
                params,
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, writeoffId: string): Promise<IGoodWriteOff> {
        try {
            const response = await Api.get<IGoodWriteOff>(`/companies/${companyId}/good-write-offs/${writeoffId}`);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IGoodWriteOffCreateDto): Promise<IGoodWriteOff> {
        try {
            const response = await Api.post<IGoodWriteOff>(`/companies/${companyId}/good-write-offs/`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, writeoffId: string, dto: IGoodWriteOffUpdateDto): Promise<IGoodWriteOff> {
        try {
            const response = await Api.put<IGoodWriteOff>(`/companies/${companyId}/good-write-offs/${writeoffId}`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, writeoffId: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/good-write-offs/${writeoffId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
